<script setup lang="ts">
export type JamBaseIconSize =
    | 'small'
    | 'medium'
    | 'large'
    | 'larger'
    | 'xl'
    | 'huge';
type JamBaseIconStroke = 'thick' | 'normal' | 'thin';

const props = defineProps({
    disabled: {
        default: false,
        type: Boolean,
    },
    iconName: {
        required: true,
        type: String,
    },
    size: {
        default: 'large',
        type: String as PropType<JamBaseIconSize>,
    },
    stroke: {
        default: 'normal',
        type: String as PropType<JamBaseIconStroke>,
    },
});

const config = useRuntimeConfig().public;

const classNames = computed(() => {
    return [
        props.disabled && 'opacity-25 cursor-default',
        props.size === 'small' && 'text-[14px]',
        props.size === 'medium' && 'text-[18px]',
        props.size === 'large' && 'text-[24px]',
        props.size === 'larger' && 'text-[32px]',
        props.size === 'xl' && 'text-[48px]',
        props.size === 'huge' && 'text-[56px]',
        props.stroke === 'normal' && 'stroke-[1px]',
        props.stroke === 'thick' && 'stroke-[1.5px]',
        props.stroke === 'thin' && 'stroke-[0.75px]',
    ];
});

const icon = ref(useNuxtData('icon-' + props.iconName)?.data ?? null);

if (!icon.value) {
    await useAsyncData('icon-' + props.iconName, () =>
        $fetch(`${config.base.url}/p/icons/${props.iconName}.svg`).then(
            (response) => response.text(),
        ),
    );
}
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <i
        v-if="icon"
        class="flex h-[1em] w-[1em] shrink-0 overflow-hidden"
        :class="classNames"
        v-html="icon"
    ></i>
    <!-- eslint-enable vue/no-v-html -->
    <JamBaseSkeletonLoader
        v-else
        class="flex h-[1em] w-[1em] shrink-0 overflow-hidden"
    />
</template>
